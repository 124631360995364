import React from 'react';
import './styles/BtnIcon.scss';
import {NavLink} from 'react-router-dom';
import {ReactComponent as ArrowRight} from '../assets/media/arrow-right.svg';
import {ReactComponent as ArrowLeft} from '../assets/media/arrow-left.svg';
import {ReactComponent as ArrowTop} from '../assets/media/arrow-top.svg';

class BtnIcon extends React.Component {
    render() {
        function BtnTypeRenderer(props, href, icon, onclick) {
            if (props === 'intern') {
                return (
                    <NavLink exact to={href}>
                        <span>
                            {icon === 'right'
                                ? <ArrowRight></ArrowRight>
                                : icon === 'top'
                                    ? <ArrowTop></ArrowTop>
                                    : <ArrowLeft></ArrowLeft>
}
                        </span>
                    </NavLink>
                )
            } else {
                return (
                    <a onClick={onclick} href={href} className="cls-btnIcon">
                        <span>
                            {icon === 'right'
                                ? <ArrowRight></ArrowRight>
                                : icon === 'top'
                                    ? <ArrowTop></ArrowTop>
                                    : <ArrowLeft></ArrowLeft>
}
                        </span>
                    </a>
                )
            }
        }
        return (
            <div className={`cls-btnIcon-wrapper`}>
                {BtnTypeRenderer(this.props.type, this.props.href, this.props.icon, this.props.onclick)}
            </div>
        )
    }
}

export default BtnIcon;