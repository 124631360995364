import React from 'react';
import './styles/Button.scss';

class Button extends React.Component {
    render() {
        return (
            <div className={`cls-btn-wrapper ${this.props.center === true ? 'cls-btn-alignCenter' : ''} ${this.props.className}`}>
                <a
                    href={this.props.href}
                    className="cls-btn"
                    target={this.props.newWindow === true
                    ? '_blank'
                    : '_self'}>
                    {this.props.children}
                </a>
            </div>
        )
    }
}

export default Button;