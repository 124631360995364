import React from 'react';
import './styles/Masonry.scss';

class Masonry extends React.Component {
    render() {
        function assets(fileName) {
            return require(`./../assets/media/${fileName}`);
        }
        const images = [
            '3DConstructor.jpg',
            'JackSparrow.jpg',
            'ThomasShelby.jpg',
            'ZanaPortrait.jpg',
            'Joker.jpg',
        ]
        return (
            <div className="cls-artworks">
                {
                    images.map((image, index) =>
                        <div key={index} className="cls-artwork">
                            <div className="cls-artwork-wrap">
                                <div className="cls-art-img">
                                    <img  
                                        alt="Logo"
                                        loading="lazy"
                                        src={assets(`artworks/${image}`)}
                                    />
                                </div>
                                
                                <div className="cls-art-title">{image.replace('.jpg', '')}</div>
                                <div className="cls-art-overlay"></div>
                            </div>
                        </div>
                    )
                }
            </div>
        )
    }
}

export default Masonry;