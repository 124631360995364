import React from 'react';
import Homepage from './pages/Homepage';
import Projects from './pages/Projects';
import Artworks from './pages/Artworks';
import Exhibitions from './pages/Exhibitions';
import AboutMe from './pages/AboutMe';
import Contact from './pages/Contact';
import NotFound from './pages/404';
import Privacy from './pages/Privacy';

import {BrowserRouter as Router, Switch, Route, Redirect} from 'react-router-dom';
import Navigation from './components/Navigation';
import { AnimatePresence } from 'framer-motion';

function App() {
    return (
        <Router>
            <Navigation/>
            <main className="Main">
                <div className="cls-main-wrapper">
                    <AnimatePresence>
                        <Switch>
                            <Route path="/" exact component={Homepage}/>
                            <Route path="/projects" component={Projects}/>
                            <Route path="/artworks" component={Artworks}/>
                            <Route path="/exhibitions" component={Exhibitions}/>
                            <Route path="/about" component={AboutMe}/>
                            <Route path="/contact" component={Contact}/>
                            <Route path="/privacy" component={Privacy}/>

                            <Route path="/404" component={NotFound} />
                            <Redirect to="/404" />
                        </Switch>
                    </AnimatePresence>
                </div>
            </main>
        </Router>
    );
}

export default App;
