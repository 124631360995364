import React from 'react';

function Contact() {
    return (
        <div className="Contact">
            Contact
        </div>
    );
}

export default Contact;