import React from 'react';
import './styles/Project.scss';
import {ReactComponent as LinkSVG} from '../assets/media/link.svg';
import {ReactComponent as HTML} from '../assets/media/html.svg';
import {ReactComponent as CSS} from '../assets/media/css.svg';
import {ReactComponent as JS} from '../assets/media/js.svg';
import {ReactComponent as Wordpress} from '../assets/media/wordpress.svg';
import {ReactComponent as PHP} from '../assets/media/php.svg';

class Project extends React.Component {
    render() {
        return (
            <div className={`cls-project-box cls-preset-${this.props.preset}`}>
                <div className="cls-project-boxLinks">
                    <a href={this.props.href} target="_blank" rel="noopener noreferrer">
                        <LinkSVG></LinkSVG>
                    </a>
                </div>
                <div className="cls-project-boxContent">
                    {this.props.children}
                </div>
            </div>
        )
    }
}
class Title extends React.Component {
    render() {
        return (
            <h4>{this.props.children}</h4>
        )
    }
}
class Tags extends React.Component {
    render() {
        return (
            <ul>
                {this.props.wp === true ? <li><Wordpress></Wordpress></li> : ''}
                {this.props.html === true ? <li><HTML></HTML></li> : ''}
                {this.props.css === true ? <li><CSS></CSS></li> : ''}
                {this.props.js === true ? <li><JS></JS></li> : ''}
                {this.props.php === true ? <li><PHP></PHP></li> : ''}
            </ul>
        )
    }
}

export {Project, Title, Tags};