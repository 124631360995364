import React from 'react';
import './styles/Section.scss';

class Section extends React.Component {
    render() {
        const fullHeight = () => {
            return this.props.fullHeight === true ? 'cls-full-height' : '';
        }
        const flex = () => {
            return this.props.flex === true ? 'cls-section-flex' : '';
        }
        const alignCenter = () => {
            return this.props.alignCenter === true ? 'cls-flex-center' : '';
        }
        return (
            <section ref={this.props.ref} className={`cls-section ${this.props.className}`}>
                <div className="cls-section-subtitle">
                    <span>{`//`}</span> {this.props.subtitle}
                </div>
                <div className={`cls-section-wrapper ${flex()} ${fullHeight()} ${alignCenter()}`}>
                    {this.props.children}
                </div>
            </section>
        )
    }
}

export default Section;