import React from 'react';
import './styles/Headline.scss';

class Headline extends React.Component {
    renderHeadline(type, string) {
        switch(type) {
            case 'h1':
                return <h1>{string}</h1>;
            case 'h2':
                return <h2>{string}</h2>;
            case 'h3':
                return <h3>{string}</h3>;
            case 'h4':
                return <h4>{string}</h4>;
            case 'h5':
                return <h5>{string}</h5>;
            case 'h6':
                return <h6>{string}</h6>;
            case 'display-1':
                return <div className="cls-display-1">{string}</div>;
            case 'display-2':
                return <div className="cls-display-2">{string}</div>;
            default:
                return <h1>{string}</h1>;
        }
      }
    render() {
        return (
            <div data-aos={this.props.anim} className={this.props.className}>
                {this.renderHeadline(this.props.type, this.props.children)}
            </div>
        )
    }
}

export default Headline;