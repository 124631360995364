import React from 'react'

function Projects() {
    return (
        <div className="App">
            Project
        </div>
    );
}

export default Projects;