import React from 'react'

function Privacy() {
    return (
        <div className="App">
            priavcye
        </div>
    );
}

export default Privacy;