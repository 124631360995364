import React from 'react';

function AboutMe() {
    return (
        <div className="App">
            About
        </div>
    );
}

export default AboutMe;