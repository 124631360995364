import React from 'react';
import { NavLink } from 'react-router-dom';
import { ReactComponent as Facebook } from '../assets/media/facebook.svg';
import { ReactComponent as Instagram } from '../assets/media/instagram.svg';
import { ReactComponent as Twitter } from '../assets/media/twitter.svg';
import { ReactComponent as Youtube } from '../assets/media/youtube.svg';
import { ReactComponent as Email } from '../assets/media/envelope.svg';

import './styles/Navigation.scss';
class Navigation extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            status: false
        };
        
    }
    menuToggleHandler = () => {
        this.setState({
            status: !this.state.status
        });
    }
    menuLinksCloseNavHandler = () => {
        this.setState({
            status: false
        });
    }
    render() {
        return (
            <div className="Navigation">
                <aside className="cls-sidebar">
                    <div className="cls-logo">
                        <NavLink exact to="/">
                            <img alt="Logo" src={ require('./../assets/media/logo.png') }/>
                        </NavLink>
                    </div>

                    <div className={`cls-burger-btn ${this.state.status === true ? 'cls-active' : ''}`} onClick={this.menuToggleHandler}>
                        <div className={`cls-burger-btn-wrapper`}>
                            <div className="cls-line cls-half cls-top"></div>
                            <div className="cls-line"></div>
                            <div className="cls-line cls-half cls-bottom"></div>
                        </div>
                        <small className="cls-line-text">MENU</small>
                    </div>

                    <div className="cls-socialmedia-desktop">
                        <ul>
                            <li><a href="#test"><Facebook></Facebook></a></li>
                            <li><a href="#test"><Instagram></Instagram></a></li>
                            <li><a href="#test"><Youtube></Youtube></a></li>
                            <li><a href="#test"><Twitter></Twitter></a></li>
                        </ul>
                    </div>

                    <div className="cls-emailme">
                        <a href="mailto:zana.karzan47@gmail.com"><Email></Email></a>
                    </div>
                </aside>

                <aside className={`cls-sidebar-navigation ${this.state.status === true ? 'cls-active' : ''}`}>
                    <div className="cls-nav-head">
                        <NavLink onClick={this.menuLinksCloseNavHandler}  exact to="/">
                            <span>Zana</span>
                        </NavLink>
                    </div>

                    <div className="cls-nav-list">
                        <ul>
                            <li><NavLink onClick={this.menuLinksCloseNavHandler} exact activeClassName="cls-active-page" to="/">Home</NavLink></li>
                            <li><NavLink onClick={this.menuLinksCloseNavHandler} exact activeClassName="cls-active-page" to="/projects">Projects</NavLink></li>
                            <li><NavLink onClick={this.menuLinksCloseNavHandler} exact activeClassName="cls-active-page" to="/artworks">Artworks</NavLink></li>
                            <li><NavLink onClick={this.menuLinksCloseNavHandler} exact activeClassName="cls-active-page" to="/exhibitions">Exhibitions</NavLink></li>
                            <li><NavLink onClick={this.menuLinksCloseNavHandler} exact activeClassName="cls-active-page" to="/about">About Me</NavLink></li>
                            <li><NavLink onClick={this.menuLinksCloseNavHandler} exact activeClassName="cls-active-page" to="/contact">Contact</NavLink></li>
                        </ul>
                    </div>

                    <div className="cls-nav-title">
                        <div className="cls-display-2">Menu</div>
                    </div>
                </aside>
                <div onClick={this.menuLinksCloseNavHandler} className={`cls-nav-overlay ${this.state.status === true ? 'cls-nav-overlayActive' : ''}`}></div>
            </div>
        )
    }
}

export default Navigation;