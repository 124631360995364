import React from 'react'

function Artworks() {
    return (
        <div className="App">
            Artwork
        </div>
    );
}

export default Artworks;