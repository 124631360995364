import React from 'react';

function Exhibitions() {
  return (
    <div className="App">
        <h1>Exhb</h1>
    </div>
  );
}

export default Exhibitions;