import React from "react";
import '../assets/styles/homepage.scss';
import TextLoop from 'react-text-loop';
import Button from '../components/Button';
import Section from '../components/Section';
import ContainerGridLines from '../components/ContainerGridLines';
import {ReactComponent as Dev} from '../assets/media/development.svg';
import {ReactComponent as Paint} from '../assets/media/paint.svg';
import Masonry from "../components/Masonry";
import Headline from "../components/Headline";
import {Project, Title, Tags} from "../components/Project";
import BtnIcon from "../components/BtnIcon";
import SliderExhbition from "../components/SliderExhbition";

import 'aos/dist/aos.css';
import AOS from 'aos';
import Footer from "../components/Footer";
function createBgDecoItems(props) {
    var elements = [];
    for (var i = 0; i < props; i++) {
        elements.push(
            <div key={i} className="cls-deco-item"></div>
        );
    }
    return elements;
}
function assets(fileName) {
    return require(`./../assets/media/${fileName}`);
}

function Homepage() {
    AOS.init({
        duration: 600
    });
    return (
        <div className="App">
            <section className="cls-hero">
                <div className="cls-hero-inner">

                    <div className="cls-hero-main">
                        <h1 className="cls-intro">
                            Hey there!
                            <br/>
                            I'm Zana Karzan
                            <br/>
                            <TextLoop >
                                <strong>Web Developer</strong>
                                <strong>Self-taught Artist</strong>
                            </TextLoop>
                        </h1>

                        <div className="cls-wrap">
                            <Button>Let's Start</Button>
                        </div>
                    </div>

                    <div className="cls-hero-bg-decoration">
                        {createBgDecoItems(12)}
                    </div>

                    <div className="cls-hero-img">
                        <div className="cls-bg"></div>
                    </div>

                    <div className="cls-hero-location">
                        <TextLoop>
                            <span>47.661942, 9.172430</span>
                            <a href="#test" className="cls-google-map" target="_blank">
                                Based in Konstanz
                            </a>
                        </TextLoop>
                    </div>

                    <div className="cls-scroll-down">
                        <div className="cls-scroll-down-wrap">
                            <div className="cls-mouse">
                                <div className="cls-scroller"></div>
                            </div>
                            <span>Scroll Down</span>
                        </div>
                    </div>
                </div>
            </section>
            <Section
                className="AboutMe"
                fullHeight={true}
                flex={true}
                alignCenter={true}
                subtitle="About Me">
                <div className="cls-row">
                    <div className="cls-first-column">
                        <div className="cls-image-wrap">
                            <div className="cls-image-title">Zana</div>
                            <img loading="lazy" alt="Zana" src={assets('Zana_Hassan_COMP1.png')}></img>
                        </div>
                    </div>
                    <div className="cls-second-column">
                        <div className="cls-main-about">
                            <Headline type="h1">Lorem ipsum</Headline>
                            <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                                Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,
                                when an unknown.</p>

                        </div>
                        <div className="cls-profession">
                            <div className="cls-box">
                                <div className="cls-box-icon">
                                    <Dev/>
                                </div>
                                <h6>Web Developer</h6>
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas in pulvinar
                                    neque.</p>
                            </div>
                            <div className="cls-box">
                                <div className="cls-box-icon">
                                    <Paint/>
                                </div>
                                <h6>Artist</h6>
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas in pulvinar
                                    neque.</p>
                            </div>
                        </div>
                        <div className="cls-btn-wrap">
                            <Button>About me ...</Button>
                        </div>
                    </div>
                </div>
                <ContainerGridLines/>
            </Section>
            <Section className="Artworks" fullHeight={false} subtitle="Artworks">
                <div className="cls-artworks-head">
                    <Headline type="display-1">My Vision On Paper</Headline>
                    <BtnIcon type="intern" href="/artworks" icon="right"/>
                </div>
                <Masonry/>
                <ContainerGridLines/>
            </Section>
            <Section className="Projects" fullHeight={false} subtitle="Projects">
                <div className="cls-project-head">
                    <Headline type="display-2">Projects I’ve Worked On</Headline>
                    <BtnIcon type="intern" href="/projects" icon="right"/>
                </div>
                <div className="cls-projects-wrapper">
                    <Project preset={1}>
                        <Title>City<br/>Logistik.</Title>
                        <Tags wp={true} css={true} js={true}/>
                    </Project>
                    <Project preset={3}>
                        <Title>SÜDKURIER<br/>Styleguide.</Title>
                        <Tags wp={true} html={true} js={true} css={true} php={true}/>
                    </Project>
                    <Project preset={2}>
                        <Title>psg<br/>BW</Title>
                        <Tags wp={true} html={true} js={true} css={true}/>
                    </Project>
                </div>
                <ContainerGridLines/>
            </Section>
            <Section className="Exhibitions" fullHeight={false} subtitle="Exhibitions">
                <div className="cls-exhibition-head">
                    <Headline type="display-2">Exhibitions I’ve Opened</Headline>
                    <BtnIcon type="intern" href="/exhibition" icon="right"/>
                </div>
                <SliderExhbition></SliderExhbition>
                <ContainerGridLines/>
            </Section>
            <Section className="Contact" fullHeight={false} subtitle="Contact Me">
                <div className="cls-contact-head">
                    <Headline type="display-1">Get In Touch</Headline>
                    <BtnIcon type="intern" href="/projects" icon="right"/>
                </div>
            </Section>
            <Footer></Footer>
        </div>
    );
}

export default Homepage;
