import React from 'react';
import {NavLink} from 'react-router-dom';
import BtnIcon from './BtnIcon';
import './styles/Footer.scss';

class Footer extends React.Component {
    scrollToTop() {
        window.scrollTo({top: 0, behavior: "smooth"});
    }
    render() {
        const date = new Date();
        return (
            <footer className="cls-footer">
                <div className="cls-wrapper">
                    <div className="cls-copyright">
                        <small>Copyright &#xA9; {date.getFullYear()} Zana. All Rights Reserved</small>
                    </div>
                    <div className="cls-buttons">
                        <div className="cls-button">
                            <small><NavLink to="/privacy">Privacy</NavLink></small>
                        </div>
                        <div className="cls-button">
                            <BtnIcon type="extern" onclick={this.scrollToTop} icon="top"/>
                        </div>
                    </div>
                </div>
            </footer>
        )
    }
}

export default Footer;