import React from "react";
import Slider from "react-slick";
import '../../node_modules/slick-carousel/slick/slick-theme.css';
import './styles/SliderExhbition.scss'

class SliderExhbition extends React.Component {
  render() {
    var settings = {
      dots: true,
      infinite: true,
      speed: 1000,
      slidesToShow: 2,
      slidesToScroll: 1,
      initialSlide: 0,
      responsive: [
        {
          breakpoint: 992,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            infinite: true,
            dots: true
          }
        },
      ],
      autoplay: true,
      autoplaySpeed: 2500
    };
    return (
      <div>
        <Slider className="cls-slick-slider" {...settings}>
          <div className="cls-img-wrapper">
              <img loading="lazy" alt="Exhibiton_Picture" src={ require('../assets/exhibition/Present_1.jpg') } />
          </div>
          <div className="cls-img-wrapper">
            <img loading="lazy" alt="Exhibiton_Picture" src={ require('../assets/exhibition/Present_2.jpg') } />
          </div>
          <div className="cls-img-wrapper">
            <img loading="lazy" alt="Exhibiton_Picture" src={ require('../assets/exhibition/Present_3.jpg') } />
          </div>
          <div className="cls-img-wrapper">
            <img loading="lazy" alt="Exhibiton_Picture" src={ require('../assets/exhibition/Present_4.jpg') } />
          </div>
          <div className="cls-img-wrapper">
            <img loading="lazy" alt="Exhibiton_Picture" src={ require('../assets/exhibition/Present_5.jpg') } />
          </div>
        </Slider>
      </div>
    );
  }
}
export default SliderExhbition;