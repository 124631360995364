import React from 'react';
import './styles/ContainerGridLines.scss';

class ContainerGridLines extends React.Component {
    render() {
        return (
            <div className="cls-lines">
                <div className="cls-lines-wrapper">
                    <div className="cls-line"></div>
                    <div className="cls-line"></div>
                    <div className="cls-line"></div>
                    <div className="cls-line"></div>
                    <div className="cls-line"></div>
                </div>
            </div>
        )
    }
}

export default ContainerGridLines;