import React from 'react';
import Button from '../components/Button';
import ContainerGridLines from '../components/ContainerGridLines';
import Headline from '../components/Headline';
import Section from '../components/Section';
import {ReactComponent as NotFoundSvg} from './../assets/media/404.svg'
function NotFound() {
  return (
    <div className="NotFound">
        <Section className="page404" subtitle="404 Error">
            <NotFoundSvg></NotFoundSvg>
            <div className="cls-utl">
                <Headline>Page Not Found</Headline>
                <Button className="cls-back" center={true} href="/">Go to Homepage</Button>
            </div>
            <ContainerGridLines></ContainerGridLines>
        </Section>
    </div>
  );
}

export default NotFound;